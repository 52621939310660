<template>
  <div>
    <kore-modal
      title="Aviso"
      :value.sync="deleteAllErrosDialog"
      @confirm="deleteAllWithError"
    >
      <p><strong>Atenção!</strong> Você está prestes a excluir todas as importações desta empresa que estão com status Erro.</p>
      <p>Dependendo da quantidade de importações, essa ação pode ser muito demorada.</p>
    </kore-modal>

    <kore-modal
      title="Encargos Patronais"
      :value.sync="visualizeEncargos"
      hideActions
    >
      <input-v
        v-model="modalData.encargos"
        :valid.sync="fields.encargos.valid"
        :label="fields.encargos"
        :editable="false"
      ></input-v>
    </kore-modal>

    <form-modal
      :cols="cols"
      :errorMessage="addDialogErroMessage"
      :opened.sync="addDialog"
      :opts="opts"
      title="Importação"
      :value.sync="modalData"
      @update:block="doClose($event)"
      @close="doClose($event)"
      @cancel="addDialog = false"
      @save="doImport"
    ></form-modal>

    <v-dialog v-model="isVisibleModalPdf" scrollable persistent max-width="500">
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-medium class="mr-3">mdi-file-chart-outline</v-icon>
          <span>Leitura de PDFs</span>
          <v-btn
            @click="isVisibleModalPdf = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="!canPDFRead">
          <p>Só é possivel realizar a leitura dos PDFs em importações de anexos de Notas Fiscais de Materiais ou Serviços de Terceiros.</p>
          <p>Por favor, selecione no filtro <b>Tipo</b> o tipo de anexo que deseja realizar a leitura.</p>
        </v-card-text>
        <v-card-text v-else>
          <p>Selecione o período para leitura dos pdfs:</p>
          <v-menu
            v-model="modalPdfMenu"
            :close-on-click="competenciaLeitura.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaLeitura | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaLeitura"
              range
              type="month"
              @click:month="modalPdfMenu = competenciaLeitura.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>

          <v-alert
            v-if="modalPdfErroMessage"
            dense
            text
            type="error"
            style="font-size: 13px"
          >
            {{ modalPdfErroMessage }}
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0 justify-center">
          <template v-if="canPDFRead">
            <v-btn
              color="secondary"
              class="pr-5"
              dark
              depressed
              @click="isVisibleModalPdf = false"
            >
              <v-icon left>mdi-chevron-left</v-icon>Cancelar
            </v-btn>
            <v-btn
              color="primary"
              dark
              depressed
              class="px-5 ml-3"
              @click="doPdfRead(); isVisibleModalPdf = false"
            >
              Executar
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="secondary"
              class="px-5"
              dark
              depressed
              @click="isVisibleModalPdf = false"
            >
              Fechar
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <GModal ref="pdfSuccess" title="Requisição bem sucedida">
      <p>Processo de OCR enviado para a Fila!</p>
    </GModal>

    <master-detail
      :actionBarButtons="actionBarButtons"
      :canDelete="canDelete"
      :canEdit="false"
      :cols="cols"
      :contextOptions="contextOptions"
      descriptionProperty="arquivo.0.nome"
      :filterQuery="filterQuery"
      formTitle="Importação"
      :hasNewButton="false"
      has-year-filter
      :hasExportXLSX="true"
      :hasExportCSV="true"
      :opts="opts"
      :customResource="customResource"
      @contextmenu="contextHandler"
      @mounted="mounted"
      @onOpenFormDialog="modalData = $event"
    >
      <div class="d-flex">
        <div class="v-label-input px-2 py-1">Tipo:</div>
        <v-autocomplete
          v-model="tipoFilter"
          :items="opts.tipos"
          item-value="id"
          item-text="descricao"
          placeholder="todos arquivos"
          dense
          class="mb-n1 mt-0"
          style="width: 250px "
          hide-details
          :clearable="!tipoImportacao"
          :readonly="!!tipoImportacao"
        ></v-autocomplete>
      </div>
    </master-detail>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { TipoImportacaoEnum } from "@/core/enums/importacoes";
import { UserTypeEnum } from '@/core/enums/user-types';

export default {
  components: {
    "form-modal": () => import("@/components/form-modal.vue"),
    GModal: () => import("@/components/g-modal.vue"),
    "kore-modal": () => import("@/components/kore-modal.vue"),
    "input-v": () => import("@/components/input-v.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'user']),
    actionBarButtons: function () {
      const actionBarButtos = [
        {
          text: "Importar",
          icon: "mdi-plus-box-outline",
          show: true,
          action: () => {
            this.addDialog = true;
            this.modalData = {
              tipo: this.tipoFilter || null,
            };
          },
        },
        {
          text: "Excluir imp. com erro",
          icon: "mdi-delete-alert-outline",
          show: this.canDelete,
          action: () => {
            this.deleteAllErrosDialog = true;
          },
        },
        {
          text: "Realizar leitura PDFs",
          icon: "mdi-file-chart-outline",
          show: true,
          action: () => {
            this.modalPdfErroMessage = "";
            this.isVisibleModalPdf = true;
          },
        }

      ];
      return actionBarButtos.filter((btn => btn.show));
    },
    customResource: function() {
      const resource = this.apiResource(`/v1/importar/${this.clientId}`);
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then((result) => {
            const ret = result.map((res) => (
              {
                ...res,
                observacao: res.observacao?.replaceAll(';', ',').replaceAll('\n', ' ')
              }
            ))
            return ret;
          })
        }
      }
    },
    canDelete: function () {
      return this.userHasAccess('Importacoes.resource.delete');
    },
    canPDFRead() {
      return [10, 11].includes(this.tipoFilter);
    },
    contextOptions: function () {
      return [
        {
          name: "Baixar Arquivo(s)",
          limit: 1,
          icon: "",
          cb: (e) => {
            (e.arquivo || []).forEach((a) => {
              if (a.url) {
                window.open(a.url);
              }
            });
          },
        },
        {
          name: "Ver Encargos Patronais",
          limit: 1,
          icon: "",
          disabled: this.disabledVisualizeEncargos,
          cb: (e) => {
            this.visualizeEncargos = true;
            this.modalData = e;
          },
        },
      ];
    },
    filterQuery: function () {
      return this.tipoFilter && this.tipoFilter != ""
        ? `tipo=${this.tipoFilter}`
        : "";
    },
    ehFoPag: function () {
      return this.isClientUser && [this.tipoImportacao, this.modalData?.tipo].includes(TipoImportacaoEnum.RH_FOPAG);
    },
    isClientUser: function () {
      return [UserTypeEnum.COLABORADOR, UserTypeEnum.GERENCIAL_CLIENTE].includes(this.user.tipo_usuario);
    },
    resourceUrl: function () {
      return `/v1/importar/${this.clientId}`;
    },
    resourceTipo: function () {
      return this.apiResource("/v1/importar/tipos");
    },
    competenciaDefault: function () {
      const data = moment();

      if (this.$store.getters.anoBase) {
        data.year(this.$store.getters.anoBase);
      }

      return data.format('YYYY-MM');
    },
    cols: function () {
      return [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
          filterable: true,
        },
        {
          key: "tipo",
          name: "Tipo",
          hideInTable: this.isClientUser,
          editable: !this.tipoImportacao,
          type: this.$fieldTypes.SELECT,
          rules: [{ rule: "required" }],
          rel: { to: "tipos", key: "id", name: "descricao" },
          colSize: 6,
        },
        {
          key: "competencia",
          name: "Competência",
          defaultValue: this.competenciaDefault,
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "usuario",
          name: "Usuário",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
          filterable: true,
        },
        {
          key: "arquivo",
          name: "Arquivo(s)",
          type: this.$fieldTypes.FILE,
          rules: [{ rule: "required" }],
          //block: true,
          max: false,
        },
        {
          key: "status_label",
          name: "Status",
          editable: false,
          hideInform: true,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "statusTxt",
          name: "Progresso",
          editable: false,
          hideInform: true,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "encargos",
          name: "Encargos patronais",
          hideInform: !this.ehFoPag,
          hideInTable: true,
          type: this.$fieldTypes.TEXTAREA,
          placeholder: 'Preencha aqui os percentuais de INSS, RAT/FAP, TERCEIROS e FGTS aplicáveis a competência.',
        },
        {
          key: "observacao",
          name: "Observação",
          type: this.$fieldTypes.TEXTAREA,
        },
        {
          key: "criado_em",
          name: "Data de Criação",
          align: 1,
          hideInform: true,
          type: this.$fieldTypes.FROMNOW,
        },
      ];
    },
    tipoImportacao: function () {
      return this.$route.meta?.tipoImportacao;
    },
    colsModalPdf: function () {
      return [
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
        },
      ]
    },
  },
  created: function () {
    if (this.clientId == 0) {
      this.goToHome();
    } else {
      this.resourceTipo.get().then((response) => {
        this.opts.tipos = response.sort(({ descricao: a }, { descricao: b }) => a.localeCompare(b));
      });
      this.tipoFilter = this.tipoImportacao;
    }
  },
  data: function () {
    const today = moment().format("YYYY-MM");
    return {
      addDialog: false,
      deleteAllErrosDialog: false,
      disabledVisualizeEncargos: false,
      addDialogErroMessage: "",
      visualizeEncargos: false,
      modalData: {},
      doLoad: () => {},
      tipoFilter: null,
      opts: {
        tipos: [],
      },
      fields: {
        encargos: { key: "encargos", name: "", type: this.$fieldTypes.TEXTAREA },
      },
      modalPdfMenu: false,
      modalPdfErroMessage: "",
      isVisibleModalPdf: false,
      competenciaLeitura: [today, today],
    };
  },
  methods: {
    contextHandler: function (v) {
      this.disabledVisualizeEncargos = v.tipo !== TipoImportacaoEnum.RH_FOPAG;
    },
    deleteAllWithError: function () {
      this.apiResource(this.resourceUrl)
        .delete('all')
        .then(({ error }) => {
          if (!error) {
            this.doLoad();
          }
        });
    },
    doClose: function(data) {
      this.cols = data.map((col) => {
        if(col.block != null) {
          col.block = true
        }
        return col;
      })
    },
    doImport: function (value, closeFn) {
      const cb = () => {
        const slug = this.opts.tipos.find(({ id }) => id === value.tipo).slug;
        const url = `${this.resourceUrl}/${slug}`;
        this.apiResource(url).save(value).then((response) => {
          if (response.errors) {
            this.addDialogErroMessage = response.message;
            return;
          }

          this.addDialogErroMessage = "";
          closeFn();
          this.doLoad();
        });
      };

      if (this.ehFoPag && !value.encargos) {
        this.showConfirm('Encargo Patronal não informado', 'Nenhum encargo patronal informado. Deseja continuar mesmo assim?', cb);
      } else {
        cb();
      }
    },
    goToHome: function () {
      this.notify({
        error: 'Não é possível acessar o módulo de importação sem uma empresa selecionada.',
      });
      this.$router.push({ name: "homepage" });
    },
    mounted: function ({ doLoad }) {
      this.doLoad = doLoad;
    },
    doPdfRead: async function () {
      try {
        const { save } = this.apiResource(`/v1/ocr/${this.clientId}`);
        const [competenciaIni, competenciaFim] = this.competenciaLeitura;
        await save({ competenciaIni, competenciaFim, tipo: this.tipoFilter });
        this.$refs.pdfSuccess.open();
      } catch (error) {
        this.modalPdfErroMessage = this.errorHandler(error);
      }
    }
  },
  watch: {
    clientId: function (value) {
      if (value == 0) {
        this.goToHome();
      }
    }
  },
};
</script>